<template>
  <div class="c-all-concepts u-px u-py">
    <VHeading header="h1"
      :text="$t('SEARCH_CONCEPTS')"
      class="o-heading"
    />

    <div class="c-all-concepts__filters o-tabs-header">
      <div class="o-tabs-header__filters">
        <div v-if="searchWord" class="o-tabs-header__count-info">
          {{ $t('SEARCH_COUNT_INFO', { count: concepts.length }) }}: <span class="o-tabs-header__search-word">{{ searchWord }}</span>
        </div>
      </div>
    </div>

    <div class="c-all-concepts__holder u-py" v-if="!loading">
      <div class="c-all-concepts__wrapper" v-if="concepts.length">
        <ConceptBox v-for="concept in concepts"
          class="c-all-concepts__item u-mb"
          :key="concept.id"
          :item="concept">
        </ConceptBox>
      </div>

      <div class="c-all-concepts__wrapper" v-else>
        No results.
      </div>
    </div>
    <div v-else
      class="c-all-concepts__holder u-py"
    >
      <div class="c-all-concepts__wrapper">
        <div v-for="i in 4"
          :key="i"
          class="c-all-concepts__item u-mb"
        >
          <ContentLoader height="400">
            <rect x="0" y="0" width="100%" height="80px"></rect>
            <rect x="20" y="150" width="90%" height="10px"></rect>
            <rect x="20" y="200" width="90%" height="10px"></rect>
            <rect x="20" y="250" width="90%" height="10px"></rect>
            <rect x="20" y="300" width="50%" height="10px"></rect>
          </ContentLoader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VHeading from '@forlagshuset/v-heading'
import ConceptBox from '@/components/ConceptBox'
import { ContentLoader } from 'vue-content-loader'

export default {
  components: {
    VHeading,
    ConceptBox,
    ContentLoader
  },

  props: {
    concepts: Array,
    searchWord: String,
    loading: Boolean
  },
}
</script>

<style lang="scss">
.c-all-concepts {
  position: relative;
}
.c-all-concepts__filters {
  position: absolute;
  right: 1rem;
  top: 4.5rem;
}
.c-all-concepts__wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;

  @include bp(medium) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include bp(large) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.c-all-concepts__item {
  box-shadow: 0.1rem 0px 0.75rem $box-shadow-color;
  background: $color-white;
  border-radius: 1.2rem;
  overflow: hidden;
  color: $color-black;
  text-decoration: none;
}
</style>