<template>
  <div class="c-article-box u-mb">
    <div class="c-article-box__col u-px u-py">
      <picture v-if="image" class="c-article-box__pic">
        <img
          :src="`${image}`"
          :title="
            cover.image_type.license && cover.image_type.license.credit
              ? cover.image_type.license.credit
              : ''
          "
          class="c-article-box__img"
        />
      </picture>
    </div>
    <div class="c-article-box__col c-article-box__col--title u-pr u-py">
      <VHeading header="h4" class="c-article-box__subtitle">
        {{ unitName }}: {{ lesson.name }}
      </VHeading>
      <VHeading header="h2" class="c-article-box__title" :text="title" />
    </div>
    <div class="c-article-box__col u-px">
      <router-link
        class="c-article-box__btn"
        :to="{
          name: 'Lesson',
          params: {
            lessonSlug: lesson.slug,
            unitSlug: unitSlug,
          },
          query: { tab: title.toLowerCase().replace(/ /g, '-') },
        }"
      >
        {{ $t('ACTIVITY_BUTTON') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { addCloudinaryUrlParams } from '@/utils'
import VHeading from '@forlagshuset/v-heading'

export default {
  components: {
    VHeading,
  },

  props: {
    title: String,
    description: String,
    unitSlug: String,
    unitName: String,
    lesson: Object,
    cover: Object,
  },

  setup(props) {
    const image = ref('')

    onMounted(() => {
      if (props.cover) {
        const coverURL = props.cover.image_type.file.url
        const param = 'h_150,c_scale/w_150,h_150,c_crop,r_max'
        image.value = addCloudinaryUrlParams(coverURL, param)
      }
    })

    return {
      image,
    }
  },
}
</script>

<style lang="scss">
.c-article-box {
  color: $color-black;
  text-decoration: none;
}
.c-article-box__subtitle {
  text-transform: uppercase;
  color: $color-grey-lighter;
  h4 {
    margin: 0;
  }
}
.c-article-box__title {
  h2 {
    margin: 0;
    font-size: 2.5rem;
    line-height: normal;
  }
}
.c-article-box__desc {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 1.75rem;
  max-width: 100%;
  p {
    margin: 0;
  }
}
.c-article-box__col {
  margin: auto 0;
  &--title {
    @include bp(840px) {
      width: 78%;
    }
  }
}
.c-article-box__btn {
  background-color: $color-brand;
  color: $color-white;
  border-width: 0;
  font-family: CircularPro, sans-serif;
  font-weight: 400;
  border-radius: 2rem;
  padding: 0.75rem 2rem;
  display: block;
  text-decoration: none;

  &:hover {
    background-color: $color-brand-medium;
  }
}
</style>
