<template>
  <div class="c-filters">
    <FHDropdown v-if="filters.length"
      class="c-filters__dropdown u-px--2 u-px"
      :selectedIndex="selectedFilter"
      :options="filters"
      :size="15"
      :label="`${$t('SEARCH_FILTERS')}:`"
      @item-selected="$emit('filter-selected', $event)" />
  </div>
</template>

<script>
import FHDropdown from '@forlagshuset/v-dropdown'

export default {
  components: {
    FHDropdown
  },

  props: {
    filters: Array,
    selectedFilter: Number
  },
}
</script>

<style lang="scss">
.c-filters {
  position: absolute;
  right: 1rem;
  top: 4rem;
  font-family: CircularPro, sans-serif;
  .c-filters__dropdown {
    position: relative;
  }

  .c-dropdown__label {
    margin-right: 1rem;
  }
  .c-dropdown__listbox {
    @include bp-down(550px) {
      position: unset;
    }
  }
  .c-dropdown__dropdown {
    border-radius: 1.25rem;
    padding: 1rem;
    right: 0;
    left: auto;
    margin: 0;

    @include bp-down(550px) {
      left: 0;
    }

    .c-dropdown__item {
      padding-right: 1em;
      font-size: 1rem;
    }

    .c-dropdown__item-icon {
      display: none;
    }
  }
  .c-dropdown__icon-arrow {
    top: 0.15rem;
    position: relative;
  }
}
</style>
