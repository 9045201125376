import { ref, onMounted } from '@vue/composition-api'
import store from '@/store'

const useFilters = (items, type, filters) => {
  if (!type) throw Error('useFilters require {type} parameter')

  const selected = ref(0)
  selected.value = store.getters['filters/getSelected']
  const filteredItems = ref([])
  const onFilterSelected = async e => {
    if (e) {
      filteredItems.value = []
      const getValue = items.value || items

      getValue.forEach(item => {
        const i = item[type] || item
        i.tags.forEach(tag => {
          if (tag === filters[e].value || tag.id === filters[e].value) {
            filteredItems.value.push(item)
          }
        })
      })
      selected.value = e
    } else {
      //show all themes
      filteredItems.value = items.value || items
    }

    await store.dispatch('filters/setSelected', e)
  }

  onMounted(() => {
    onFilterSelected(selected.value)
  })

  return {
    filters,
    selected,
    filteredItems,
    onFilterSelected
  }
}

export {
  useFilters
}