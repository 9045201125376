<template>
  <div class="c-concept-box">
    <ConceptElement :content="item" />
    <div class="c-concept-box__content-wrapper">
      <div class="c-concept-box__content" v-md-html="item.description" />
      <a v-if="item.content.length" class="c-concept-box__see-more" href="#" @click.prevent="$store.dispatch('modal/setModal', item)">
        {{ $t('CONCEPT_SEE_FULL_DESC') }}
      </a>
    </div>
  </div>
</template>

<script>
import ConceptElement from '@/components/ConceptElement'

export default {
  components: {
    ConceptElement
  },

  props: {
    item: Object
  },
}
</script>

<style lang="scss">
.c-concept-box {
  box-shadow: 0.1rem 0px 0.75rem $box-shadow-color;
  background: $color-white;
  border-radius: 1.2rem;
  overflow: hidden;
}

.c-concept-box__content-wrapper {
  padding: 1rem 1.5rem 1.5rem;
  font-family: CircularPro, sans-serif;
  color: lighten($color-black, 25%);
}

.c-concept-box__see-more {
  color: inherit;
  display: block;
  text-align: center;
}
</style>