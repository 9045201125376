<template>
  <div class="c-all-articles u-px u-py">
    <VHeading header="h1"
      :text="$t('SEARCH_ARTICLES')"
      class="o-heading"></VHeading>
    <Filters :filters="filters" 
      :selectedFilter="selected"
      @filter-selected="onFilterSelected($event)" />
    <div v-if="filteredItems.length"
      class="c-all-articles__list">
      <ArticleBox v-for="article in filteredItems"
        :key="article.id"
        :title="article.article_type.name"
        :description="article.article_type.content[0].body"
        :lesson="article.lesson"
        :unit-name="unit.name"
        :unit-slug="unit.slug"
        :cover="article.article_type.cover"
        class="c-all-articles__box u-mb">
      </ArticleBox>
    </div>
    <div v-else class="c-all-articles__list">
      <div
        v-for="i in 4"
        :key="i"
        class="c-all-articles__box u-px u-mb"
      >
        <ContentLoader width="150" height="150">
          <circle cx="75" cy="75" r="75" ></circle>
        </ContentLoader>
        <ContentLoader width="600" height="150">
          <rect x="1rem" y="0" width="50%" height="1rem"></rect>
          <rect x="1rem" y="2rem" width="65%" height="2rem"></rect>
          <rect x="1rem" y="5rem" width="95%" height=".75rem"></rect>
          <rect x="1rem" y="6rem" width="100%" height=".75rem"></rect>
          <rect x="1rem" y="7rem" width="45%" height=".75rem"></rect>
        </ContentLoader>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import VHeading from '@forlagshuset/v-heading'
import ArticleBox from '@/components/ArticleBox'
import Filters from '@/components/Filters'
import { useFilters } from '@/composition/filters'

export default {
  components: {
    VHeading,
    ArticleBox,
    Filters,
    ContentLoader
  },

  props: {
    articles: Array,
    unit: Object,
    filters: Array
  },

  setup (props) {
    const { filteredItems, selected, onFilterSelected } = useFilters(props.articles, 'article_type', props.filters)

    return {
      selected,
      onFilterSelected,
      filteredItems
    }
  }
}
</script>

<style lang="scss">
.c-all-articles__box {
  background: $color-white;
  overflow: hidden;
  text-align: center;
  border-radius: 1.2rem;
  box-shadow: 0.1rem 0px 0.75rem $box-shadow-color;

  @include bp(840px) {
    display: flex;
    max-height: 185px;
    height: 185px;
    border-radius: 150px;
    text-align: left;
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    width: 600px;
    &:first-of-type {
      width: 150px
    }
  }
}
</style>