<template>
  <router-link
    class="c-theme-tile"
    :to="{
      name: 'Lesson',
      params: { lessonSlug: lessonSlug, unitSlug: unitSlug },
    }"
    :lang="locale"
  >
    <picture v-if="cover" class="c-theme-tile__pic">
      <img
        :src="`${cover}`"
        :alt="altText"
        :title="imageCredit"
        class="c-theme-tile__img"
      />
    </picture>
    <VHeading header="h2" class=" c-theme-tile__title">
      {{ title }}
    </VHeading>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
import VHeading from '@forlagshuset/v-heading'

export default {
  components: {
    VHeading,
  },

  computed: {
    ...mapGetters({
      locale: 'appConfig/getLocale',
    }),
  },

  props: {
    title: String,
    cover: String,
    altText: String,
    imageCredit: String,
    lessonSlug: String,
    unitSlug: String,
  },
}
</script>

<style lang="scss">
.c-theme-tile__title {
  text-align: center;
  display: flex;
  height: 6rem;
  text-align: center;
  hyphens: auto;

  h2 {
    font-size: 1.65rem;
    line-height: 1;
    margin: auto 1rem;
    width: 100%;
  }
}
</style>
