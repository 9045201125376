var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-article-box u-mb"},[_c('div',{staticClass:"c-article-box__col u-px u-py"},[(_vm.image)?_c('picture',{staticClass:"c-article-box__pic"},[_c('img',{staticClass:"c-article-box__img",attrs:{"src":("" + _vm.image),"title":_vm.cover.image_type.license && _vm.cover.image_type.license.credit
            ? _vm.cover.image_type.license.credit
            : ''}})]):_vm._e()]),_c('div',{staticClass:"c-article-box__col c-article-box__col--title u-pr u-py"},[_c('VHeading',{staticClass:"c-article-box__subtitle",attrs:{"header":"h4"}},[_vm._v(" "+_vm._s(_vm.unitName)+": "+_vm._s(_vm.lesson.name)+" ")]),_c('VHeading',{staticClass:"c-article-box__title",attrs:{"header":"h2","text":_vm.title}})],1),_c('div',{staticClass:"c-article-box__col u-px"},[_c('router-link',{staticClass:"c-article-box__btn",attrs:{"to":{
        name: 'Lesson',
        params: {
          lessonSlug: _vm.lesson.slug,
          unitSlug: _vm.unitSlug,
        },
        query: { tab: _vm.title.toLowerCase().replace(/ /g, '-') },
      }}},[_vm._v(" "+_vm._s(_vm.$t('ACTIVITY_BUTTON'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }