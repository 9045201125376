<template>
  <div class="c-themes u-px u-py o-wrapper">
    <VHeading header="h1"
      :text="title"
      class="c-themes__title o-heading" />
    <Filters :filters="filters" 
      :selectedFilter="selected"
      @filter-selected="onFilterSelected($event)" />
    <div v-if="!loading">
      <div v-if="filteredItems.length" class="c-themes__lessons">
        <LessonTile v-for="theme in filteredItems"
        :key="theme.id"
        class="c-themes__lesson"
        :title="theme.name"
        :cover="theme.coverUrl"
        :image-credit="theme.imageCredit"
        :alt-text="theme.altText"
        :lesson-slug="theme.slug"
        :unit-slug="unit.slug" />
      </div>
      <div v-else class="c-themes__lessons">
        <LessonTile v-for="theme in lessons"
          :key="theme.id"
          class="c-themes__lesson"
          :title="theme.name"
          :cover="theme.coverUrl"
          :alt-text="theme.altText"
          :lesson-slug="theme.slug"
          :unit-slug="unit.slug" />
      </div>
    </div>
    <div v-else class="c-themes__lessons">
      <div v-for="i in 6"
        :key="i"
        class="c-themes__lesson">
        <ContentLoader height="400">
          <rect x="0" y="0" width="100%" height="330"></rect>
        </ContentLoader>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { ContentLoader } from 'vue-content-loader'
import VHeading from '@forlagshuset/v-heading'
import LessonTile from '@/components/LessonTile'
import Filters from '@/components/Filters'
import { useFilters } from '@/composition/filters'

export default {
  components: {
    VHeading,
    LessonTile,
    Filters,
    ContentLoader
  },

  props: {
    filters: Array,
    unit: Object,
    lessons: Array,
    loading: Boolean
  },

  setup(props) {
    const title = computed(() => props.unit.name)
    const themes = computed(() => props.lessons)

    const { filteredItems, selected, onFilterSelected } = useFilters(themes, 'lessons', props.filters)

    return {
      title,
      selected,
      onFilterSelected,
      filteredItems
    }
  }
}
</script>

<style lang="scss">
.c-themes {
  min-height: calc(100vh - 17rem)
}
.c-themes__lessons {
  display: grid;
  grid-gap: 2rem;
  
  justify-items: center;

  @include bp(775px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include bp(1175px) {
    justify-items: left;
    grid-template-columns: repeat(3, 1fr);
  }
}

.c-themes__lesson {
  box-shadow: 0.1rem 0px 0.75rem $box-shadow-color;
  background: $color-white;
  border-radius: 1.2rem;
  max-width: 330px;
  width: 100%;
  overflow: hidden;
  color: $color-black;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  transition-property: background, color;

  &:hover {
    background-color: $color-brand;
    color: $color-white;
  }
}
</style>
