<template>
  <div class="c-search">
    <Logotype class="c-search__bg u-py u-px" />
    <!-- search container -->
    <FHTabs
      class="c-search__tabs"
      :tabable="true"
      ref="tabs"
      :initial-tab="initTab"
      @changed="onChangeTab"
    >
      <FHTab :name="$t('SEARCH_CONCEPTS')">
        <AllConcepts
          :concepts="filteredConcepts"
          :searchWord="searchWord"
          :loading="loadingConcepts"
        />
      </FHTab>
      <FHTab :name="$t('SEARCH_ARTICLES')">
        <AllArticles
          :articles="filteredArticles"
          :unit="themaUnit"
          :filters="filters"
          :loading="loadingArticles"
        />
      </FHTab>
      <FHTab :name="$t('SEARCH_THEMES')">
        <ThemaUnit
          :lessons="filteredLessons"
          :unit="themaUnit"
          :filters="filters"
          :loading="loadingLessons"
        />
      </FHTab>
      <!-- <FHTab :name="$t('SEARCH_MULTIMEDIA')">
        <Multimedia :unit="media" :loading="loadingMedia" />
      </FHTab> -->
      <template v-if="$route.query.tab === 'begreper'" #after-tablist>
        <div class="c-search-input__wrapper">
          <label class="c-search-input__label">
            <input
              class="c-search-input"
              v-model="searchWord"
              :placeholder="$t('SEARCH_PLACEHOLDER')"
            />
            <div class="c-search-input__icon">
              <template>
                <!-- There is an issue in TAB component with including VueJS Components from application level -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M225.474 0C101.151 0 0 101.151 0 225.474c0 124.33 101.151 225.474 225.474 225.474 124.33 0 225.474-101.144 225.474-225.474C450.948 101.151 349.804 0 225.474 0zm0 409.323c-101.373 0-183.848-82.475-183.848-183.848S124.101 41.626 225.474 41.626s183.848 82.475 183.848 183.848-82.475 183.849-183.848 183.849z"
                  />
                  <path
                    d="M505.902 476.472L386.574 357.144c-8.131-8.131-21.299-8.131-29.43 0-8.131 8.124-8.131 21.306 0 29.43l119.328 119.328A20.74 20.74 0 00491.187 512a20.754 20.754 0 0014.715-6.098c8.131-8.124 8.131-21.306 0-29.43z"
                  />
                </svg>
              </template>
            </div>
          </label>
        </div>
      </template>
    </FHTabs>

    <Modal />
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import * as JsSearch from 'js-search'
import {
  fetchCourseBySlug,
  fetchUnitBySlug,
  fetchUnitLessons,
} from '@/services/strapi'
import { componentNameNormalize, addCloudinaryUrlParams } from '@/utils'
import { FHTabs, FHTab } from '@forlagshuset/v-tabs'
import AllConcepts from '@/components/AllConcepts'
import AllArticles from '@/components/AllArticles'
import ThemaUnit from '@/components/ThemaUnit'
import Logotype from '@/assets/svg/Logotype'
import Modal from '@/components/Modal'
// import Multimedia from '@/components/Multimedia'

export default {
  components: {
    FHTabs,
    FHTab,
    AllConcepts,
    AllArticles,
    ThemaUnit,
    Logotype,
    Modal,
    // Multimedia
  },

  setup(props, ctx) {
    const appSlug = ctx.root.$store.state.appConfig.slug
    const originalConcepts = ref([])
    const filteredConcepts = ref([])
    const filteredArticles = ref([])
    const themaUnit = ref({})
    const searchWord = ref('')
    const search = new JsSearch.Search('id')
    search.addIndex('name')
    const onChangeTab = ref(() => {})
    const loadingConcepts = ref(true)
    const loadingArticles = ref(true)
    const loadingLessons = ref(true)
    const loadingMedia = ref(true)
    const filteredLessons = ref([])
    const filters = ref([])
    const tabs = ref(null)
    const media = ref({})
    const initTab = ref(ctx.root.$route.query.tab)

    onChangeTab.value = selectedTab => {
      if (selectedTab.tab.$el.id !== ctx.root.$route.query.tab) {
        if (ctx.root.$route.query.tab) {
          ctx.root.$router.push({ query: { tab: selectedTab.tab.$el.id } })
        } else {
          ctx.root.$router.replace({ query: { tab: selectedTab.tab.$el.id } })
        }
      }
    }
    onMounted(async () => {
      const response = await fetchCourseBySlug(appSlug)

      filters.value.push({
        value: 0,
        label: ctx.root.$t('NAV_ACTIVITY_CARDS'),
      })
      response.tags.forEach(tag => {
        filters.value.push({
          value: tag.id,
          label: tag.label,
        })
      })

      originalConcepts.value = response.concepts.sort(function(a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
      filteredConcepts.value = originalConcepts.value
      const conceptsToIndex = filteredConcepts.value.map(concept => {
        const arrayOfBodies = []

        if (concept.description) {
          arrayOfBodies.push(concept.description)
        }

        if (concept.content.length) {
          concept.content.forEach(content => {
            if (content.body) {
              arrayOfBodies.push(content.body)
            }
          })
        }

        concept.searchableContent = arrayOfBodies

        return concept
      })

      search.addDocuments(conceptsToIndex)
      loadingConcepts.value = false

      const themaSlug = appSlug === 'aktor' ? 'aktor-tema' : 'aktor-tema-nn'
      themaUnit.value = await fetchUnitBySlug(themaSlug)
      filteredLessons.value = await fetchUnitLessons(themaSlug)
      loadingLessons.value = false

      filteredLessons.value.forEach(lesson => {
        if (lesson.cover && lesson.cover.image_type) {
          const coverURL = lesson.cover.image_type.file.url
          const param = 'h_330,c_scale/w_330,h_330,c_crop'
          lesson.coverUrl = addCloudinaryUrlParams(coverURL, param)
          lesson.altText = lesson.cover.image_type.altText
        }

        //add lessons to LE
        lesson.learningElementSection.forEach(element => {
          if (
            componentNameNormalize(element.__component) ===
            'ComponentLearningElementArticleElement'
          ) {
            element.lesson = lesson
            filteredArticles.value.push(element)
          }
        })
      })
      loadingArticles.value = false

      const mediaSlug =
        appSlug === 'aktor' ? 'aktor-multimedia' : 'aktor-multimedia-nn'
      media.value = await fetchUnitBySlug(mediaSlug)
      loadingMedia.value = false
    })

    watch(
      () => searchWord.value,
      val => {
        if (val) {
          filteredConcepts.value = search.search(val)
        } else {
          filteredConcepts.value = originalConcepts.value
        }
      },
    )
    watch(
      () => ctx.root.$route.query.tab,
      (val, prev) => {
        if (ctx.refs.tabs && val !== prev) {
          ctx.refs.tabs.setActive(`#${val}`)
        }
      },
    )

    return {
      filteredConcepts,
      filteredArticles,
      themaUnit,
      media,
      searchWord,
      onChangeTab,
      loadingConcepts,
      loadingArticles,
      loadingLessons,
      loadingMedia,
      filters,
      filteredLessons,
      tabs,
      initTab,
    }
  },
}
</script>

<style lang="scss">
.c-search {
  position: relative;
  min-height: calc(100vh - 10rem);

  .c-all-articles > .c-header,
  .c-themes > .c-header {
    margin-bottom: 2rem;

    @include bp-down(550px) {
      margin-bottom: 5rem;
    }
  }

  .c-filters {
    @include bp-down(550px) {
      top: 6.5rem;
      left: 0;
      right: 0;
    }
  }
}

.c-search__bg {
  position: absolute;
  top: 5rem;
  bottom: 0;
  left: 0;
  right: 0;
}
.c-search-input {
  font-family: CircularPro, sans-serif;
  box-sizing: border-box;

  &::placeholder {
    font-weight: 700;
  }
}
.c-search__tabs {
  position: relative;
  z-index: 1;

  .c-tabs__panels-wrapper,
  .c-tab__panel {
    max-width: $xlarge;
    margin: 0 auto;
    background: none;
    position: relative;
  }
  .c-tabs__list-wrapper {
    padding: 1rem 10%;
    display: flex;
    justify-content: space-between;

    @include bp-down(970px) {
      text-align: center;
    }
  }
  .c-tabs__btn {
    background-color: transparent;
    border: none;
    &.c-tabs__btn--active {
      background-color: $color-brand;
      &:before {
        background-color: $color-brand;
      }
    }
    &:hover {
      background-color: $color-brand-medium;
      color: $color-white;
      &:before {
        background-color: $color-brand-medium;
      }
    }
  }
}

.c-search-input {
  border: none;
  border: 1px solid rgba(35, 35, 35, 0.1);
  border-radius: 0;
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  width: 100%;
}

.c-search-input__label {
  position: relative;
  display: inline-flex;
  margin-top: 2rem;

  @include bp(970px) {
    display: flex;
    margin-top: 0;
  }

  &:before {
    content: '';
    border-left: 4px solid transparent;
  }

  &:focus-within {
    &:before {
      border-left-color: $color-brand;
    }
  }
}

.c-search-input__icon {
  position: absolute;
  right: -1.4rem;
  top: -0.35rem;
  bottom: 0;
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 50%;
  background: $color-brand;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.6rem;
  }
}
</style>
